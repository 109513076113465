import React from 'react';
import styled from 'styled-components';
import {
  Divider,
  Paragraph,
  BaseButton,
  Secondary,
  BaseLink
} from 'aether-marketing';
import ModalComponent from '../v5_modal-overlays/ModalComponent';
import SignUpPostmanEmail from '../SignUpPostmanEmailField';

// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding-top: ${(props) => props.paddingTop || `60px`};
  padding-bottom: ${(props) => props.paddingBottom || `60px`};
  .vide-btn {
    ${Secondary.componentStyle.rules}
  }
  .video-btn-white {
    color: white !important;
    border: 1px solid white;
    border-radius: ${(props) => props.theme.borderRadius.small};
  }
  @media screen and (max-width: 567px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .reduce-img {
    height: 100%;
    @media (min-width: 768px) {
      height: 375px;
    }
  }
`;
const ParentTitleTemp = styled.p`
  color: ${(props) => props.theme.colors.grey_90};
  font-weight: 600;
`;

const ParentTitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const ParentSubtitleText = styled.p`
  color: ${(props) => props.theme.colors.orange_40};
  text-transform: uppercase;
  font-weight: 600;
`;

const heroWithModalAndImage = (data) => {
  const colLeftClass = data.colLeft ? `col-lg-${data.colLeft}` : 'col-lg-6';
  const colRightClass = data.colRight ? `col-lg-${data.colRight}` : 'col-lg-6';
  return (
    <>
      <Section
        paddingTop={data.paddingTop || null}
        paddingBottom={data.paddingBottom || null}
        style={{
          backgroundImage: data.background,
          backgroundColor: data.backgroundColor
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className={`col-sm-12 ${colLeftClass} text-lg-left text-center align-self-center order-2 order-lg-1 mt-5 mt-sm-0`}
            >
              {data.link && (
                <div className="mb-4">
                  <BaseLink
                    className="text-white"
                    src={data.link.src}
                    linkType="reversedArrowLink"
                  >
                    {data.link.text}
                  </BaseLink>
                </div>
              )}
              {data.parentTitle && (
                <div className="row">
                  <div className="col-12">
                    <ParentTitleText>{data.parentTitle}</ParentTitleText>
                  </div>
                </div>
              )}
              {data.parentTitleTemp && (
                <div className="row">
                  <div className="col-12">
                    <ParentTitleTemp className="mb-0">
                      {data.parentTitleTemp}
                    </ParentTitleTemp>
                  </div>
                </div>
              )}
              <h1 className={`mb-4 ${data.className}`}>{data.titles}</h1>
              {data.parentSubtitle && (
                <div className="row">
                  <div className="col-12">
                    <ParentSubtitleText>
                      {data.parentSubtitle}
                    </ParentSubtitleText>
                  </div>
                </div>
              )}
              <Paragraph className={`subtitle ${data.className}`}>
                {data.body}
              </Paragraph>
              {data.cta && (
                <BaseButton
                  as="a"
                  buttonType={data.cta.buttonType}
                  src={data.cta.src}
                  target={data.cta.target}
                  className={data.cta.className}
                >
                  {data.cta.text}
                </BaseButton>
              )}
              {data.modal && (
                <ModalComponent
                  className={` ${data.button.className || 'video-btn'}`}
                  modal={data.modal}
                  button={data.button}
                  isSecondary={data.button.isSecondary}
                />
              )}
              {data.links && (
                <BaseButton
                  buttonType="secondary"
                  className={`mt-3 ${data.className}`}
                  as="a"
                  src={data.links.src}
                  target={data.links.target}
                  dataTest={data.links.target}
                >
                  {data.links.text}
                </BaseButton>
              )}
              {data.isSignUpForm && (
                <div className="row">
                  <div className="col-12 col-lg-9">
                    <SignUpPostmanEmail />\
                  </div>
                </div>
              )}
              {data.footer && (
                <div className="row d-flex flex-row">
                  <div className="col-12">
                    <div
                      dangerouslySetInnerHTML={{ __html: data.footer.text }}
                    />
                  </div>
                  <div className="col-12">
                    {data.footer.links.map((link) => (
                      <a
                        key={pmUuid()}
                        className="inline mr-3"
                        href={link.href}
                      >
                        {link.image && (
                          <img
                            src={link.image.src}
                            alt={link.image.alt}
                            className="img-fluid"
                          />
                        )}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`col-sm-12 ${colRightClass} align-self-center order-1 order-lg-2 text-center`}
            >
              {data.media ? (
                <img
                  className="img-fluid"
                  src={data.media[0].image}
                  alt={data.media[0].alt}
                  fetchpriority="high"
                  loading="eager"
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Section>
      <Divider fullWidth />
      <span data-sticky={data.stickyAttribute || null} />
    </>
  );
};

export default heroWithModalAndImage;
