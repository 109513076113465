import React from 'react';
import styled from 'styled-components';
import { Divider, SectionStyles, BaseButton } from 'aether-marketing';

// eslint-disable-next-line import/no-extraneous-dependencies
import pmUuid from '@postman/uuid';

/* eslint-disable react/prop-types */
const LogoWrapper = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: ${(props) =>
    props.mediaControl.height ? props.mediaControl.height : '46px'};
  margin: 0 auto;

  @media (max-width: 576px) {
    height: 100%; //works with grid-template-columns: repeat(4, 1fr);
  }

  &:hover {
    &.hover {
      transform: translateY(-0.5rem);
      transition: 0.3s linear;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.mediaControl.columns
      ? `repeat(${props.mediaControl.columns}, 1fr)`
      : 'repeat(12, 1fr)'};
  gap: 24px;
  padding: 0;
  @media (max-width: 576px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const GridItem = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  @media (max-width: 576px) {
    padding: 20px;
  }
`;

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const renderImage = (img, toggleHover, linkedImages, mediaControl) => (
  <GridItem key={pmUuid()}>
    {linkedImages ? (
      <a
        href={img.href}
        target="_blank"
        rel="noopener noreferrer"
        aria-labelledby="listen"
      >
        <InnerWrapper>
          <LogoWrapper
            mediaControl={mediaControl}
            style={{ backgroundImage: `url(${img.src})` }}
            className={toggleHover ? 'hover' : ''}
          />
          {img.text && !linkedImages && (
            <p className="text-white text-center mt-3">{img.text}</p>
          )}
        </InnerWrapper>
      </a>
    ) : (
      <InnerWrapper>
        <LogoWrapper
          mediaControl={mediaControl}
          style={{ backgroundImage: `url(${img.src})` }}
          title={img.alt}
          className={toggleHover ? 'hover' : ''}
        />
      </InnerWrapper>
    )}
    {img.text && linkedImages && (
      <p className="text-white text-center mt-3">{img.text}</p>
    )}
  </GridItem>
);

function LogoGrid({ images, toggleHover, linkedImages, mediaControl }) {
  return (
    <Grid mediaControl={mediaControl}>
      {images.map((img) =>
        renderImage(img, toggleHover, linkedImages, mediaControl)
      )}
    </Grid>
  );
}

let viewed = false;

function LogoWallSideBySide(data) {
  const {
    buttonLeft,
    buttonRight,
    backgroundColor,
    body,
    divider,
    padding,
    mediaControl,
    title,
    media,
    linkedImages,
    toggleHover,
    event
  } = data;

  const target =
    typeof document === 'object' &&
    document.getElementById('CustomersLogoWall');
  if (target && event && !viewed) {
    target.setAttribute('data-event-view', event);
    viewed = true;
  }

  return (
    <>
      <SectionStyles
        id="CustomersLogoWall"
        style={{
          backgroundColor,
          padding
        }}
      >
        <div className="container">
          <div className="row d-flex">
            <div className="col-12 col-lg-4 align-content-center mb-5 mb-lg-0">
              <h2>{title}</h2>
              <div dangerouslySetInnerHTML={{ __html: body.join('') }} />
              {buttonLeft && (
                <div className="row mt-5">
                  <div className="col-sm-12 col-md-8">
                    {buttonLeft.text && buttonLeft.src && (
                      <BaseButton
                        buttonType={buttonLeft.buttonType}
                        as="a"
                        src={buttonLeft.src}
                        target={buttonLeft.target}
                        id={buttonLeft.id}
                        gaCategory={buttonLeft.gaCategory}
                        gaLabel={buttonLeft.gaLabel}
                        event={buttonLeft.event}
                        eventProp={buttonLeft.eventProp}
                        className="mb-3 mb-md-0"
                        icon={buttonLeft.icon}
                      >
                        {buttonLeft.text}
                      </BaseButton>
                    )}
                    {buttonRight && buttonRight.text && buttonRight.src && (
                      <BaseButton
                        buttonType={buttonRight.buttonType}
                        as="a"
                        className="mr-0"
                        src={buttonRight.src}
                        target={buttonRight.target}
                        id={buttonRight.id}
                        gaCategory={buttonRight.gaCategory}
                        gaLabel={buttonRight.gaLabel}
                        event={buttonRight.event}
                        eventProp={buttonRight.eventProp}
                        icon={buttonRight.icon}
                      >
                        {buttonRight.text}
                      </BaseButton>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-lg-8">
              <LogoGrid
                images={media}
                toggleHover={toggleHover}
                linkedImages={linkedImages}
                mediaControl={mediaControl}
              />
            </div>
          </div>
        </div>
      </SectionStyles>
      {divider && <Divider />}
    </>
  );
}

export default LogoWallSideBySide;
