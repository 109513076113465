const cards = [
  {
    id: 'FlixBus',
    featured: true,
    backgroundImage: '',
    tags: ['Travel & Hospitality'],
    subtitle: {
      text: 'FlixBus drives productivity for testing by saving 10 hours per month with Postman Collections'
    },
    media: {
      alt: 'FlixBus logo',
      src: 'https://voyager.postman.com/logo/flixbus-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/flixbus/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Sanofi',
    featured: true,
    backgroundImage: '',
    tags: ['Retail, POS, & eCommerce', 'Pharmaceutical'],
    subtitle: {
      text: 'Workspaces elevate collaboration and enable up to 60 releases per week for Sanofi'
    },
    media: {
      alt: 'Sanofi logo',
      src: 'https://voyager.postman.com/logo/external/sanofi-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/sanofi/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Hubspot',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology'],
    subtitle: {
      text: ' HubSpot increases API calls by 104% in 6 months with a Postman Public Workspace'
    },
    media: {
      alt: 'Hubspot logo',
      src: 'https://voyager.postman.com/logo/external/hubspot-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/hubspot/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Youi2',
    featured: true,
    backgroundImage: '',
    tags: ['Insurance', 'Financial Services', 'Software & Technology'],
    subtitle: {
      text: 'Postman saves Youi more than 35 minutes in testing time while enabling harmonious collaboration'
    },
    media: {
      alt: 'Youi logo',
      src: 'https://voyager.postman.com/logo/external/youi-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/youi/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'California State University',
    featured: true,
    backgroundImage: '',
    tags: ['Education'],
    subtitle: {
      text: 'Postman Academy enables California State University to reduce testing time from 2 weeks to 1 day'
    },
    media: {
      alt: 'CSU logo',
      src: 'https://voyager.postman.com/logo/external/csu-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/csu/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Siemens',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology'],
    subtitle: {
      text: 'Postman Public Workspaces builds community and bolsters collaboration for Siemens'
    },
    media: {
      alt: 'Siemens logo',
      src: 'https://voyager.postman.com/logo/external/siemens-logo-blue.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/siemens/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Avenu',
    featured: true,
    backgroundImage: '',
    tags: ['Financial Services'],
    subtitle: {
      text: 'Banking-as-a-Service platform Avenu sees Partner Workspaces accelerate integration from 3 months to 2 weeks'
    },
    media: {
      alt: 'Avenu logo',
      src: 'https://voyager.postman.com/logo/external/avenu-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/avenu/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'TotalEnergies',
    featured: true,
    backgroundImage: '',
    tags: ['Operations & Logistics'],
    subtitle: {
      text: '100% of developers at TotalEnergies Digital Factory agree that Postman creates effortless collaboration'
    },
    media: {
      alt: 'TotalEnergies logo',
      src: 'https://voyager.postman.com/logo/external/total-energies-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/total-energies/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Canopy Servicing',
    featured: true,
    backgroundImage: '',
    tags: ['Financial Services'],
    subtitle: {
      text: 'Partner Workspaces reduced API activation time from 3 weeks to 5 days for Canopy'
    },
    media: {
      alt: 'Canopy Servicing logo',
      src: 'https://voyager.postman.com/logo/external/canopy-servicing-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/canopy-servicing/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'SlingTV',
    featured: true,
    backgroundImage: '',
    tags: ['Media & Entertainment'],
    subtitle: {
      text: 'Sling TV makes 70% improvement in its development cycle with the Postman API Platform'
    },
    media: {
      alt: 'Sling logo',
      src: 'https://voyager.postman.com/logo/external/sling-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/sling/',
      hrefType: 'internal',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Amadeus',
    featured: true,
    backgroundImage: '',
    tags: ['Travel & Hospitality'],
    subtitle: {
      text: 'Postman shrinks ticket resolution time from 30 minutes to 5 for Amadeus'
    },
    media: {
      alt: 'Amadeus logo',
      src: 'https://voyager.postman.com/logo/external/amadeus-logo.png',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/amadeus/',
      hrefType: 'internal',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'PayPal',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology', 'Financial Services'],
    subtitle: {
      text: 'PayPal decreases the time to first call to 1 minute with Postman'
    },
    media: {
      alt: 'PayPal logo',
      src: 'https://voyager.postman.com/logo/external/paypal-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/paypal/',
      hrefType: 'internal',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'WhatsApp Business',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology', 'Communications'],
    subtitle: {
      text: 'WhatsApp prioritizes quality developer experiences with Postman'
    },
    media: {
      alt: 'WhatsApp Business logo',
      src: 'https://voyager.postman.com/logo/external/whatsapp-business-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/whatsapp/',
      hrefType: 'internal',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Axis Bank',
    featured: true,
    backgroundImage: '',
    tags: ['Financial Services'],
    subtitle: {
      text: 'New product releases triple as Postman fuels innovation for Axis Bank'
    },
    media: {
      alt: 'Axis Bank logo',
      src: 'https://voyager.postman.com/logo/external/axis-bank-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/axis-bank/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Visma',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology', 'Operations & Logistics'],
    subtitle: {
      text: 'Visma reduces API bug-fixing time to under 10 minutes with Postman'
    },
    media: {
      alt: 'Visma logo',
      src: 'https://voyager.postman.com/logo/external/visma-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/visma/',
      hrefType: 'internal',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Western Governors University',
    featured: true,
    backgroundImage: '',
    tags: ['Education'],
    subtitle: {
      text: 'Western Governors University reduces testing time from 96 hours to 5-6 with Postman'
    },
    media: {
      alt: 'Western Governors University logo',
      src: 'https://voyager.postman.com/logo/external/wgu-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/wgu/',
      hrefType: 'internal',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'OVO',
    featured: true,
    backgroundImage: '',
    tags: ['Financial Services'],
    subtitle: {
      text: 'OVO decreases the time to launch a new service from 2 weeks to 2 days with Postman'
    },
    media: {
      alt: 'OVO logo',
      src: 'https://voyager.postman.com/logo/external/ovo-logo-purple.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/ovo/',
      hrefType: 'internal',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Twitter',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology', 'Communications'],
    subtitle: {
      text: 'Twitter grows its developer ecosystem with Postman and the Postman API Network'
    },
    media: {
      alt: 'Twitter logo',
      src: 'https://voyager.postman.com/logo/external/twitter-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/twitter/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Anima International',
    featured: true,
    backgroundImage: '',
    tags: ['Nonprofit'],
    subtitle: {
      text: 'Anima Denmark focuses resources on Animal Welfare by saving over 100 hours annually with Postman'
    },
    media: {
      alt: 'Anima International logo',
      src: 'https://voyager.postman.com/logo/external/anima-international-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/anima-international/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'The Trevor Project',
    featured: true,
    backgroundImage: '',
    tags: ['Nonprofit'],
    subtitle: {
      text: 'The Trevor Project streamlines API collaboration workflows with Postman to support LGBTQ youth'
    },
    media: {
      alt: 'The Trevor Project logo',
      src: 'https://voyager.postman.com/logo/external/the-trevor-project-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/the-trevor-project/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'SEI Novus',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology', 'Financial Services'],
    subtitle: {
      text: 'SEI Novus accelerates feature release deployment from weeks to hours with Postman'
    },
    media: {
      alt: 'SEI Novus logo',
      src: 'https://voyager.postman.com/logo/external/sei-novus-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/sei-novus/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Extend',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology'],
    subtitle: {
      text: 'Extend saves 60 minutes per day having chosen Postman for efficiency from day one'
    },
    media: {
      alt: 'Extend logo',
      src: 'https://voyager.postman.com/logo/external/extend-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/extend/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Reputation',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology', 'Enterprise'],
    subtitle: {
      text: 'Reputation builds a custom test automation framework on Postman'
    },
    media: {
      alt: 'Reputation logo',
      src: 'https://voyager.postman.com/logo/external/reputation-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/reputation/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Werner Enterprises',
    featured: true,
    backgroundImage: '',
    tags: ['Operations & Logistics'],
    subtitle: {
      text: 'Werner Enterprises saves 3 hours per week by using Postman'
    },
    pdf: {
      downloadThisSection: 'generate',
      downloadFileName: 'case-study-werner-postman'
    },

    media: {
      alt: 'Werner Enterprises logo',
      src: 'https://voyager.postman.com/logo/external/werner-enterprises-logo.png',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/werner/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Built',
    featured: true,
    backgroundImage: '',
    tags: ['Construction', 'Financial Services'],
    subtitle: {
      text: 'Built Technologies takes an API-first, repeatable approach to development on Postman'
    },
    media: {
      alt: 'Built logo',
      src: 'https://voyager.postman.com/logo/external/built-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/built/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Toast',
    featured: true,
    backgroundImage: '',
    tags: [
      'Travel & Hospitality',
      'Financial Services',
      'Retail, POS, & eCommerce'
    ],
    subtitle: {
      text: 'Toast achieves an improvement to developer productivity of 95 minutes per week for engineers using Postman'
    },
    media: {
      alt: 'Toast logo',
      src: 'https://voyager.postman.com/logo/external/toast-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/toast/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Hathway',
    featured: true,
    backgroundImage: '',
    tags: ['Travel & Hospitality', 'Retail, POS, & eCommerce'],
    subtitle: {
      text: 'Hathway cuts production time in half writing custom tests when using Postman'
    },
    media: {
      alt: 'Hathway logo',
      src: 'https://voyager.postman.com/logo/external/hathway-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/hathway/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Code for Baltimore',
    featured: true,
    backgroundImage: '',
    tags: ['Nonprofit', 'Software & Technology'],
    subtitle: {
      text: 'Postman helps Code for Baltimore simplify community-driven API development'
    },
    media: {
      alt: 'Code for Baltimore logo',
      src: 'https://voyager.postman.com/logo/external/cfb-logo.png',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/code-for-baltimore/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Youi',
    featured: true,
    backgroundImage: '',
    tags: ['Insurance', 'Financial Services', 'Software & Technology'],
    subtitle: {
      text: 'Youi automates 2,700+ test runs per deployment on Postman'
    },
    media: {
      alt: 'Youi logo',
      src: 'https://voyager.postman.com/logo/external/youi-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/youi-automation/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'ChargeHub',
    featured: true,
    backgroundImage: '',
    tags: ['Operations & Logistics', 'Travel & Hospitality'],
    subtitle: {
      text: 'ChargeHub increases developer productivity and improves API quality with Postman'
    },
    media: {
      alt: 'ChargeHub logo',
      src: 'https://voyager.postman.com/logo/external/chargehub-trans-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/chargehub/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Lightspeed Commerce',
    featured: true,
    backgroundImage: '',
    tags: ['Travel & Hospitality', 'Retail, POS, & eCommerce'],
    subtitle: {
      text: 'Lightspeed reduces onboarding time and solidifies API governance with Postman'
    },
    media: {
      alt: 'Lightspeed Commerce logo',
      src: 'https://voyager.postman.com/logo/external/lightspeed-logo-b.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/lightspeed-commerce/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Cvent',
    featured: true,
    backgroundImage: '',
    tags: ['Software & Technology', 'Travel & Hospitality'],
    subtitle: {
      text: 'Cvent transforms API discovery and quality with Postman Collections'
    },
    media: {
      alt: 'Cvent logo',
      src: 'https://voyager.postman.com/logo/external/cvent-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/cvent/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Paylocity',
    featured: false,
    tags: ['Software & Technology', 'Operations & Logistics'],
    backgroundImage: '',
    subtitle: {
      text: 'Paylocity QA teams drive test automation with Postman'
    },
    media: {
      alt: 'Paylocity logo',
      src: 'https://voyager.postman.com/logo/external/paylocity-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/paylocity/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Box',
    featured: true,
    tags: ['Software & Technology', 'Communications'],
    backgroundImage: '',
    subtitle: {
      text: 'Box transforms API onboarding with Postman automation tools'
    },
    media: {
      alt: 'Box logo',
      src: 'https://voyager.postman.com/logo/external/box-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/box/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Intuit',
    featured: false,
    backgroundImage: '',
    tags: ['Financial Services', 'Software & Technology'],
    subtitle: {
      text: 'Postman is a quickstart for QuickBooks Online API endpoints'
    },
    media: {
      alt: 'Intuit logo',
      src: 'https://voyager.postman.com/logo/external/intuit-logo.png',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/intuit/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  },
  {
    id: 'Bettercloud',
    featured: false,
    backgroundImage: '',
    tags: ['Software & Technology', 'Operations & Logistics'],
    subtitle: {
      text: 'Postman monitoring helps BetterCloud optimize and automate API testing'
    },
    media: {
      alt: 'BetterCloud logo',
      src: 'https://voyager.postman.com/logo/external/bettercloud-logo.svg',
      ratio: '2/1'
    },
    link: {
      src: '/case-studies/bettercloud/',
      target: 'same-tab',
      text: 'Learn more',
      type: 'tertiary'
    },
    hover: true,
    clickableCardLink: true
  }
];
export default cards;
